<div class="modal-header">
  <button class="close-button" mat-icon-button type="button" (click)="onCancel()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="modal-body">
  <div class="text-center" mat-dialog-content>
    <img *ngIf="welcomeDialogImage$ | async as welDialogImg" class="image-container" [src]="welDialogImg"/>
  </div>
</div>
