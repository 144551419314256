<lc-card>
  <ng-template #header>
    <lc-header header="Version History">
      <ng-template #suffix>
        <button mat-icon-button (click)="onClose()">
          <mat-icon>close</mat-icon>
        </button>
      </ng-template>
    </lc-header>
  </ng-template>
  <ng-template #content>
    <div *ngIf="(fieldVersions$|async) as fieldVersions" class="fx-row">
      <div *ngIf="fieldVersions?.length"
           class="version-table-container clean-scroll-bar fx-1-1">
        <table class="mat-elevation-z8 w-100 version-table" id="field-version-table" mat-table
               [dataSource]="fieldVersions">

          <!--- Note that these columns can be defined in any order.
                            The actual rendered columns are set as a property on the row definition" -->

          <!-- modifiedTime Column -->
          <ng-container matColumnDef="modifiedTime">
            <th *matHeaderCellDef mat-header-cell> Date/Time </th>
            <td *matCellDef="let element" id="field-version-date" mat-cell style="width: 23%">
              {{element.modifiedTime * 1000|date:'MM-dd-yyyy h:mm a'}}
            </td>
          </ng-container>

          <!-- Key Column -->
          <ng-container matColumnDef="userFullName">
            <th *matHeaderCellDef mat-header-cell> Who </th>
            <td *matCellDef="let element" class="pr-2" id="field-version-who" mat-cell
                style="width: 23%">{{element.userFullName}}
            </td>
          </ng-container>
          <!-- Key Column -->
          <ng-container matColumnDef="key">
            <th *matHeaderCellDef mat-header-cell> Field </th>
            <td *matCellDef="let element" class="pr-2" id="field-version-key" mat-cell
                style="width: 23%">{{element.key}}
            </td>
          </ng-container>

          <!-- value Column -->
          <ng-container matColumnDef="value">
            <th *matHeaderCellDef mat-header-cell> Value </th>
            <td *matCellDef="let element" class="pr-2" id="field-version-value" mat-cell
                style="width: 40%">
              <div *ngIf="element.value">
                <div *ngIf="((element.value).toString() === ('assets/images/welcome-house.png'))"
                     class="field-version">
                  This Photo was removed</div>
                <div *ngIf="!(((element.value).toString().includes('https://listing-concierge')) || ((element.value).toString().includes('https://media'))) && !((element.value).toString() === ('assets/images/welcome-house.png'))" class="field-version"
                     [innerHTML]="(element.value)">
                </div>
                <a *ngIf="(((element.value).toString().includes('https://listing-concierge')) || ((element.value).toString().includes('https://media')))" target="_blank"
                   href="{{element.value}}">
                  This Photo was used</a>
              </div>
            </td>
          </ng-container>
          <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
          <tr *matRowDef="let row; columns: displayedColumns;" id="field-version-row" mat-row></tr>
        </table>
      </div>
    </div>
  </ng-template>
</lc-card>