<lc-base-dialog [message]="data.message" [otherActions]="data.readonly ? [] : [data.cancelAction]" [primaryAction]="data.acceptAction" [title]="data.title" (actionClicked)="onActionClicked($event)">

  <ng-template #content>
    <form id="reason-form" [formGroup]="reasonForm">
      <!-- CSS class is for test selector only.  No UI implications. -->
      <p class="reason-message mat-body-1">{{data.message}}</p>

      <!-- if the modal contains editable fields -->
      <ng-container *ngIf="!data.readonly">
        <ng-container *ngIf="data.acceptAction.text === 'Refund'">
          <lc-dropdown #orderDropdown class="store-order"
                       displayPath="display" idPrefix="store-order"
                       label="Order" placeholder="Please select an order to refund" valuePath="id" [formControl]="reasonForm.getControl('storeOrder')" [options]="orderOptions"
                       (selectionChanged)="onOrderSelected(orderDropdown.selected?.model.id)" />
          <lc-multiselect
            *ngIf="lineItemOptions?.length"
            #dateSelect
            class="fx-1-1-0"
            displayPath="display"
            label="Line Items"
            valuePath="id"
            [deselectAllEnabled]="true"
            [displayOptions]="lineItemOptionsDisplay"
            [formControl]="reasonForm.getControl('lineItems')"
            [multiple]="true"
            [options]="lineItemOptions"
            (selectionChanged)="onLineItemSelected($event)"
          />
        </ng-container>
        <lc-dropdown class="reason" idPrefix="reason" label="Reason" placeholder="Please select a reason from the list" [formControl]="reasonForm.getControl('reason')" [options]="data.reasons" (selectionChanged)="changeReason()" />

        <div *ngIf="!hideNotes">
          <lc-textarea idPrefix="reason-note" label="Details" placeholder="Please provide more details..." [formControl]="reasonForm.getControl('notes')" />
        </div>

        <ng-container *ngIf="data.acceptAction.text === 'Refund'">
          <lc-textbox idPrefix="reason-amount" label="Refund"
                      placeholder="$0" [formControl]="reasonForm.getControl('amount')" [inputMask]="refundMask" [maskDropSpecialCharacters]="true"
          />
          <div>The maximum refund can be up to {{ maxRefundAmount | currency }}</div>
        </ng-container>
      </ng-container>

      <!-- if the modal is readonly -->
      <ng-container *ngIf="data.readonly">
        <div *ngIf="reasonForm.getControl('reason').value" class="mb-3">
          <div class="font-weight-bolder">REASON</div>
          <!-- CSS class is for test selector only.  No UI implications. -->
          <div class="reject-reason-text">{{reasonForm.getControl('reason').value}}</div>
        </div>
        <div *ngIf="reasonForm.getControl('notes').value">
          <div class="font-weight-bolder">DETAILS</div>
          <!-- CSS class is for test selector only.  No UI implications. -->
          <div class="reject-notes-text">{{reasonForm.getControl('notes').value}}</div>
        </div>
      </ng-container>
    </form>
  </ng-template>
</lc-base-dialog>
