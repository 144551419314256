<h4 id="bulk-assign-title" mat-dialog-title>{{data.title}}</h4>
<hr/>
<p id="bulk-assign-subtitle">{{data.subTitle}}</p>

<!--Modal Body-->
<div class="bulk-assign-modal-body">
  <lc-agent-search idPrefix="coordinator-search" label="Search by Coordinator:" placeholder="Search by coordinator name" [class.ng-valid]="error" [formControl]="coordinatorSearch" [max]="1" [searchBy]="roleTypeForReassign" (selectedChanged)="onCoordinatorSelected($event)" />
  <mat-error *ngIf="error" id="bulk-assign-error" [innerHTML]="error" />
</div>

<!--Modal Footer -->
<div mat-dialog-actions>
  <lc-form-buttons class="w-100" id="bulk-assign-buttons" (cancel)="onCancel()" (save)="onCommit()" />
</div>
