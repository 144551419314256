<lc-base-dialog id="notes-dialog" [header]="data?.title" [otherActions]="[cancelAction]" [primaryAction]="primaryAction" [subheader]="data?.subtitle" (close)="closeDialog()">



  <!--Modal Body-->
  <ng-template #content>
    <div class="container border px-3 mb-3" id="notes-container">
      <div *ngFor="let note of notes$ | async" class="fx-row fx-gap-sm note">
        <mat-icon class="material-icons-outlined mt-xs">description</mat-icon>
        <div class="fx-column fx-gap-0 note-container">
          <span class="d-block text-faded note-author">{{note.author}} {{note.audit?.createdAt | date:'short'}}</span>
          <span class="d-block note-text">{{note.text}}</span>
        </div>
      </div>
    </div>

    <form [formGroup]="form">
      <lc-textarea idPrefix="note" label="Add a Note" [formControl]="form.get('text')" />
    </form>
  </ng-template>
</lc-base-dialog>

